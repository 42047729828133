:local {
  .white {
    background-image: url("../../../../../../../../components/Form/components/InputSection/images/white.png");
    background-repeat: repeat-x;
    background-size: 20px 20px;
  }
  .gray {
    background-image: url("../../../../../../../../components/Form/components/InputSection/images/gray.png");
    background-repeat: repeat-x;
    background-size: 20px 20px;
  }
}
:local {
  .window {
    position: fixed;
    z-index: 50;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  }
}

:local {
  .row {
    display: flex;
    margin-bottom: 1rem;
    z-index: 2000;

    .handle {
      flex: 0 0 27px;
      padding: 7px 0;
      color: #999;
      font-size: 16px;
    }

    .onHover { display: none; }
    &:hover .onHover { display: block; }
  }
}
